@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip:after {
  content: "";
  position: absolute;
  left: 23px;
  bottom: -15px;
  border-top: 8px solid #111827;
  border-right: 7px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 7px solid transparent;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

input {
  background-color: transparent;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

input:disabled {
  opacity: 1 !important;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: #9ca3afff;
  background-color: transparent;
}

select {
  appearance: none;
  cursor: pointer;
  background-image: url("assets/icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
}

select:disabled {
  opacity: 1 !important;
}

.PhoneInputInput {
  outline: none;
}

.visualizer-border {
  border-top: 1px dashed #888888;
  border-image: repeating-linear-gradient(
      to right,
      #888888 0,
      #888888 3px,
      transparent 3px,
      transparent 5px
    )
    1;
}

/* note tab generate pdf */

.generated-html {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
}
.generated-html > .last-line {
  height: 6px;
  width: 1px;
}

.generated-html > ul > li {
  margin-left: 14px;
  padding-left: 16px;
  position: relative;
}
.generated-html > ul > li::before {
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background-color: black;
  top: 16px;
  left: 0;
  position: absolute;
}
.generated-html > ul > ul > li {
  margin-left: 30px;
  padding-left: 16px;
  position: relative;
}
.generated-html > ul > ul > li::before {
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 6px;
  border: 1px solid black;
  top: 16px;
  left: 0;
  position: absolute;
}
.generated-html > ul > ul > ul > li {
  margin-left: 46px;
  padding-left: 16px;
  position: relative;
}
.generated-html > ul > ul > ul > li::before {
  content: "";
  height: 5px;
  width: 5px;
  background-color: black;
  top: 16px;
  left: 0;
  position: absolute;
}
.generated-html > ul > ul > ul > ul > li {
  margin-left: 62px;
  padding-left: 16px;
  position: relative;
}
.generated-html > ul > ul > ul > ul > li::before {
  content: "";
  height: 5px;
  width: 5px;
  background-color: black;
  top: 16px;
  left: 0;
  position: absolute;
}

.generated-html ol {
  counter-reset: li;
}
.generated-html > ol li {
  padding-left: 30px;
  position: relative;
}
.generated-html > ol > ol li {
  margin-left: 16px;
  padding-left: 30px;
  position: relative;
}
.generated-html > ol > ol > ol li {
  margin-left: 32px;
  padding-left: 30px;
  position: relative;
}
.generated-html > ol > ol > ol > ol li {
  margin-left: 48px;
  padding-left: 30px;
  position: relative;
}
.generated-html ol li::before {
  content: counter(li) ".";
  counter-increment: li;
  left: 0;
  width: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  align-self: flex-end;
  justify-self: flex-end;
  position: absolute;
}

/* encounter tab */
.read-more-clipped-corner {
  clip-path: polygon(
    0 0,
    0 100%,
    calc(100% - 80px) 100%,
    calc(100% - 80px) calc(100% - 15px),
    100% calc(100% - 15px),
    100% 0
  );
}

/* notes forms */
.note-form-space-button {
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  background-image: url("assets/icons/dots-space.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px;
}
.note-form-space-button:hover {
  background-color: #eaf1ff;
  background-image: url("assets/icons/dots-space-dark.svg");
}

/* date pickers */
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-popper {
  z-index: 50;
}
.react-datepicker__tab-loop {
  width: 0;
  border: none;
}
.react-datepicker {
  font-family: "Mulish", sans-serif;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
}
.react-datepicker__header {
  background-color: white;
  border: none;
  padding: 0;
}

/* font size in fields on mobile  */
@media (max-width: 767px) {
  input:not([type]),
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  select,
  select:focus,
  textarea,
  textarea:focus {
    font-size: 16px !important;
  }

  [contenteditable="true"] {
    font-size: 16px !important;
  }
}

/* dynamic viewport if supported */

.h-screen-dynamic {
  height: 100vh;
}
.min-h-screen-dynamic {
  min-height: 100vh;
}
.max-h-screen-dynamic {
  max-height: 100vh;
}

.h-30vh-dynamic {
  height: 30vh;
}
.h-35vh-dynamic {
  height: 35vh;
}
.h-36vh-dynamic {
  height: 36vh;
}
.h-40vh-dynamic {
  height: 40vh;
}
.h-50vh-dynamic {
  height: 50vh;
}
.h-58vh-dynamic {
  height: 58vh;
}
.h-60vh-dynamic {
  height: 60vh;
}
.h-80vh-dynamic {
  height: 80vh;
}
.max-h-50vh-dynamic {
  max-height: 50vh;
}
.max-h-65vh-dynamic {
  max-height: 65vh;
}
.max-h-70vh-dynamic {
  max-height: 70vh;
}
.max-h-75vh-dynamic {
  max-height: 75vh;
}
.max-h-80vh-dynamic {
  max-height: 80vh;
}
.max-h-85vh-dynamic {
  max-height: 85vh;
}
.max-h-90vh-dynamic {
  max-height: 90vh;
}

@supports (height: 100dvh) {
  .h-screen-dynamic {
    height: 100dvh;
  }
  .min-h-screen-dynamic {
    min-height: 100dvh;
  }
  .max-h-screen-dynamic {
    max-height: 100dvh;
  }

  .h-30vh-dynamic {
    height: 30dvh;
  }
  .h-35vh-dynamic {
    height: 35dvh;
  }
  .h-36vh-dynamic {
    height: 36dvh;
  }
  .h-40vh-dynamic {
    height: 40dvh;
  }
  .h-50vh-dynamic {
    height: 50dvh;
  }
  .h-58vh-dynamic {
    height: 58dvh;
  }
  .h-60vh-dynamic {
    height: 60dvh;
  }
  .h-80vh-dynamic {
    height: 80dvh;
  }
  .max-h-50vh-dynamic {
    max-height: 50dvh;
  }
  .max-h-65vh-dynamic {
    max-height: 65dvh;
  }
  .max-h-70vh-dynamic {
    max-height: 70dvh;
  }
  .max-h-75vh-dynamic {
    max-height: 75dvh;
  }
  .max-h-80vh-dynamic {
    max-height: 80dvh;
  }
  .max-h-85vh-dynamic {
    max-height: 85dvh;
  }
  .max-h-90vh-dynamic {
    max-height: 90dvh;
  }
}
