.react-tel-input {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
}

.react-tel-input :disabled {
  cursor: default;
}

.react-tel-input .form-control {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  border-radius: 8px;
  width: 100%;
}

.react-tel-input .flag-dropdown {
  background-color: transparent;
  border: none;
}
.react-tel-input .flag-dropdown:after {
  content: "";
  height: 50%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #d1d5db;
}

.react-tel-input .flag-dropdown.open {
  background-color: transparent;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent;
}

.react-tel-input .selected-flag {
  background-color: transparent;
  width: 50px;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent;
}

.react-tel-input .selected-flag .arrow {
  border: none;
  background-image: url("@assets/icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-size: 10px;
  width: 10px;
  height: 10px;
  left: 24px;
}

.react-tel-input .selected-flag .arrow.up {
  border: none;
}

.react-tel-input .country-list {
  max-height: 100px;
  box-shadow: 0 1px 7px rgb(0 0 0 / 21%);
  border-radius: 8px;
  margin: 3px 0 0 0;
}

@media (max-width: 768px) {
  .react-tel-input .country-list {
    max-height: 170px;
  }
}
