.customYearMonthPicker .react-datepicker__header {
  background-color: white;
  border: none;
}

.customYearMonthPicker .react-datepicker__month-text--selected,
.customYearMonthPicker .react-datepicker__month-text--selected:hover {
  background-color: #2970ff;
  color: white;
}

.customYearMonthPicker .react-datepicker__month .react-datepicker__month-text {
  width: 50px;
  border-radius: 32px;
  margin: 6px;
}
