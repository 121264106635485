.customMonthPickerPopper {
  padding-left: 65px;
}

.customYearPickerPopper {
  padding-right: 15px;
}

.customYearPicker .react-datepicker__year-wrapper {
  max-width: 216px;
}

.customWeekPicker .react-datepicker__day,
.customRangePicker .react-datepicker__day {
  border-radius: 0;
  width: 32px;
  height: 32px;
  margin-inline: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 32px;
  font-weight: 500;
}

.customWeekPicker .react-datepicker__day--in-range,
.customRangePicker .react-datepicker__day--in-range {
  background-color: white;
}

.customRangePicker .react-datepicker__day--selected {
  border-bottom-left-radius: 32px;
  border-top-left-radius: 32px;
  background-color: white;
}

.customRangePicker .react-datepicker__day--in-selecting-range {
  background-color: #eaf1ff;
  color: #121212;
}

.customWeekPicker .react-datepicker__day--disabled,
.customRangePicker .react-datepicker__day--disabled,
.customMonthPicker .react-datepicker__month-text--disabled,
.customYearPicker .react-datepicker__year-text--disabled,
.customWeekPicker .react-datepicker__day--outside-month,
.customRangePicker .react-datepicker__day--outside-month {
  color: #d0d5dd;
  background: white !important;
}

.customWeekPicker .react-datepicker__day--today,
.customRangePicker .react-datepicker__day--today,
.customMonthPicker .react-datepicker__month-text--today,
.customYearPicker .react-datepicker__year-text--today {
  color: #2970ff;
}

.customMonthPicker .react-datepicker__month,
.customYearPicker .react-datepicker__year {
  margin: 0;
}

.customMonthPicker .react-datepicker__month-text,
.customYearPicker .react-datepicker__year-text {
  margin: 6px;
  height: 32px;
  border-radius: 32px;
  font-size: 14px;
  line-height: 32px;
  font-weight: 500;
}

.customMonthPicker .react-datepicker__month-text {
  width: 52px;
}

.customYearPicker .react-datepicker__year-text {
  width: 60px;
}

.customMonthPicker .react-datepicker__month-text--selected,
.customYearPicker .react-datepicker__year-text--selected {
  background-color: #2970ff;
  color: white;
}

.customRangePicker .react-datepicker__week {
  display: flex;
  height: 40px;
}
